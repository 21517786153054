<template>
  <div class="main-frame">
    <div class="success-text">
      <img src="../assets/images/img_done.png">
      <h3>구매 요청이 완료되었습니다.</h3>
      <p>구매해주셔서 감사합니다 :)</p>
    </div>
    <div class="success-box">
      <div class="check-container">
        <mdb-form-inline>
          <div class="check-box">
            <mdb-input class="payment-check" type="radio" id="payment-1" name="groupRadios2" radioValue="1" v-model="radioValue" label="구매한 제품을 가입한 이메일로 받기"/>
          </div>
          <div class="check-box">
            <mdb-input class="payment-check" type="radio" id="payment-2" name="groupRadios2" radioValue="2" v-model="radioValue" label="깃허브 스토리지 링크를 통해 받기"/>
            <input type="text" class="custom-input-box" placeholder="깃허브 아이디 입력" v-model="gitId"
                   :class="{disabled: radioValue !== '2'}">
          </div>
          <div class="check-box">
            <mdb-input class="payment-check" type="radio" id="payment-3" name="groupRadios2" radioValue="3" v-model="radioValue" label="구매한 제품을 다른 이메일로 받기"/>
            <input type="text" class="custom-input-box" placeholder="이메일 주소 입력" v-model="email"
                   :class="{disabled: radioValue !== '3'}">
          </div>
        </mdb-form-inline>
      </div>
      <button class="custom-btn" @click="moveMain">완료하기</button>
    </div>
  </div>
</template>

<script>
import {mdbFormInline, mdbInput} from "mdbvue";
import {firestore} from "@/firebase/firebaseConfig";

export default {
  name: "ItemPurchase",
  components: {
    mdbInput,
    mdbFormInline,
  },
  data() {
    return {
      uid: this.$route.params.uid,
      radioValue: '0',
      gitId: '',
      email: '',
      supply: {
        how: '',
        email: ''
      }
    }
  },
  watch: {
    radioValue() {
      this.gitId = ''
      this.email = ''
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      const self = this;
      firestore.collection('receipt')
          .where('itemInfo.docId', '==', self.uid)
          .where('isPayment', '==', false)
          .where('userInfo.uid', '==', self.$store.state.uid)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              self.itemId = doc.id
            })
          })
    },
    moveMain() {
      const self = this;

      switch (self.radioValue) {
        case '0':
          return alert('원하는 지급방법을 선택해 주세요')
        case '1':
          self.supply.how = 'email'
          self.supply.email = self.$store.state.user.email;
          break;
        case '2':
          self.supply.how = 'git'
          if (self.gitId.trim().length === 0) return alert('깃허브 아이디를 입력해 주세요')
          else self.supply.email = self.gitId;
          break;
        case '3':
          self.supply.how = 'anotherEmail'
          if (self.email.trim().length === 0) return alert('이메일을 입력해 주세요')
          else self.supply.email = self.email;
          break;
      }

      self.updateData()
    },
    updateData() {
      const self = this;

      firestore.collection('receipt')
          .doc(self.itemId)
          .set({supply: self.supply}, {merge: true})
          .then(() => {
            self.$router.push('/')
          })
    }
  }
}
</script>

<style scoped>
.main-frame {
  width: 1224px;
  margin: 0 auto;
  padding: 60px 16px;
}

.main-frame .success-text img {
  width: 96px;
  height: 96px;
}

.main-frame .success-text h3 {
  feeont-size: 24px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: center;
}

.main-frame .success-text p {
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 30px;
}

.main-frame .success-box {
  width: 300px !important;
  margin: 0 auto;
}

.success-box .check-container::v-deep(.form-check-input[type="radio"]:checked+label),
.success-box .check-container::v-deep(.form-check-input[type="radio"]:not(:checked)+label) {
  padding-left: 22px
}

.success-box .check-container::v-deep(.form-check-input[type="radio"]:checked+label:after),
.success-box .check-container::v-deep(.form-check-input[type="radio"]:checked+label:after) {
  background: #ff8a00;
  border: #ff8a00;
}

.success-box .check-container .check-box {
  padding: 16px;
  border-radius: 20px;
  background: #F4F4F5;
  margin-bottom: 10px;
  width: 300px;
}

.success-box .check-container .check-box .payment-check {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}

.success-box .check-container .check-box .custom-input-box {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  outline: none;
}

.success-box .check-container .check-box .custom-input-box::placeholder {
  color: #C7C6CD;
}


.main-frame .success-box .custom-btn {
  width: 100%;
  margin-top: 30px;
}
</style>